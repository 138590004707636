import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Checkbox, CircularProgress, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {fileStatusMap} from "../../views/Batches/file_status_info";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";

export function ConfirmCancelDialog(props) {
    const [reason, setReason] = useState();
    const handleConfirm = () => {
        props.onConfirm(reason);
    }
    return <Dialog
        maxWidth="xs"
        fullWidth
        open={props.open}
        onClose={props.onClose}
    >
        <DialogContent style={{padding: "24px 32px"}}>
            <Typography variant={"subtitle2"} style={{fontSize: "16px"}} gutterBottom>Confirm ?</Typography>
            <Typography variant={"subtitle1"}>
                You are about to Cancel a Batch.
                Once cancelled, Batch will move to original status.
            </Typography>
            <TextField
                fullWidth
                label="Cancellation Reason"
                multiline
                rows={5}
                required
                name={"internal_admin_comments"}
                variant="standard"
                onChange={(e) => setReason(e.target.value)}
            />
        </DialogContent>
        <DialogActions className={"center"} style={{padding: "16px"}}>
            <Button disableElevation onClick={props.onClose} variant={"contained"}>
                Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant={"contained"}>
                Yes,Confirm
            </Button>
        </DialogActions>
    </Dialog>;
}

export function FileStatusUpdate(props) {
    const [reason, setReason] = useState();
    const [status, setStatus] = useState();
    const [missingPage, setMissingPage] = useState(false);
    const [includeAnalysis, setIncludeAnalysis] = useState(false);

    const handleConfirm = () => {
        props.onConfirm({
            "internal_admin_comments": reason,
            "file_status": status,
            "has_missing_page": missingPage,
            "include_analysis": includeAnalysis
        });
    }

    const fileStatus = Object.keys(fileStatusMap);
    return <Dialog
        maxWidth="xs"
        fullWidth
        open={props.open}
        onClose={props.onClose}
    >
        <DialogContent style={{padding: "24px 32px"}}>
            <Typography variant={"subtitle2"} style={{fontSize: "16px"}} gutterBottom>Change File Status ?</Typography>
            <TextField
                color="primary"
                margin="dense"
                defaultValue={props?.fileStatus}
                select
                fullWidth
                variant="outlined"
                value={status}
                onChange={(e) => {
                    setStatus(e.target.value)
                }}
            >
                {fileStatus.map((status, i) => (
                    <MenuItem key={status} value={status}>
                        {status}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                fullWidth
                label="Cancellation Reason"
                multiline
                rows={5}
                required
                name={"internal_admin_comments"}
                variant="standard"
                onChange={(e) => setReason(e.target.value)}
            />
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={missingPage}
                            onChange={(e) => setMissingPage(e.target.checked)}
                            name="has_missing_page"
                            color="primary"
                        />
                    }
                    label="Has Missing Page"
                />
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={includeAnalysis}
                            onChange={(e) => setIncludeAnalysis(e.target.checked)}
                            name="include_analysis"
                            color="primary"
                        />
                    }
                    label="Include in Analysis"
                />
            </div>
        </DialogContent>
        <DialogActions className={"center"} style={{padding: "16px"}}>
            <Button disableElevation onClick={props.onClose} variant={"contained"}>
                Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant={"contained"}>
                Yes,Confirm
            </Button>
        </DialogActions>
    </Dialog>;
}

export function DeleteConfirmDialog(props) {
    const {content, loading} = props;
    return <Dialog
        maxWidth="xs"
        fullWidth
        open={props.open}
        onClose={props.onClose}
    >
        <DialogContent style={{padding: "24px 32px"}}>
            <Typography variant={"subtitle2"} style={{fontSize: "16px"}} gutterBottom>Confirm
                Delete?</Typography>
            <Typography variant={"subtitle1"}>
                {content}
            </Typography>
        </DialogContent>
        <DialogActions className={"center"} style={{padding: "16px"}}>
            <Button disableElevation onClick={props.onClose} variant={"contained"}>
                Cancel
            </Button>
            <Button disabled={loading} onClick={props.onConfirm} color="primary" variant={"contained"}
                    startIcon={loading && <CircularProgress/>}>
                Yes,Confirm
            </Button>
        </DialogActions>
    </Dialog>;
}

export function ManualQuickResponseDialog(props) {
    const [ETA, setETA] = useState(60);
    const [scanQuality, setScanQuality] = useState("");
    const [reason, setReason] = useState("");

    const handleConfirm = () => {
        const manualETA = moment().utc().add(ETA, "minutes").format("YYYY-MM-DD HH:mm:ss");
        props.onConfirm({"manual_eta": manualETA, "manual_reason": reason, "scan_quality": scanQuality});
    }

    const ETAOptions = [60, 90, 120, 150, 180, 210, 240];
    const complexityOptions = ["Low", "Medium", "High"];
    const scanQualityOptions = ["Good", "Moderate", "Poor"];

    return <Dialog
        maxWidth="xs"
        fullWidth
        open={props.open}
        onClose={props.onClose}
    >
        <DialogContent style={{padding: "24px 32px"}}>
            <Typography variant={"subtitle2"} style={{fontSize: "16px"}} gutterBottom>
                Submit Manual Quick Response
            </Typography>
            <FormControl fullWidth>
                <TextField
                    label="ETA"
                    color="primary"
                    margin="dense"
                    select
                    fullWidth
                    variant="outlined"
                    value={ETA}
                    onChange={(e) => {
                        setETA(e.target.value)
                    }}
                >
                    {ETAOptions.map((min, i) => (
                        <MenuItem key={min} value={min}>
                            {min/60} hour
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label="Complexity"
                    color="primary"
                    margin="dense"
                    select
                    fullWidth
                    variant="outlined"
                    value={reason}
                    onChange={(e) => {
                        setReason(e.target.value)
                    }}
                >
                    {complexityOptions?.map((op, i) => (
                        <MenuItem key={op} value={op}>
                            {op}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
            <FormControl component="fieldset">
                <FormLabel component="legend">Scan Quality</FormLabel>
                <RadioGroup name="scanQuality" value={scanQuality}
                            onChange={(event, value) => setScanQuality(value)}>
                    {scanQualityOptions?.map(op => (<FormControlLabel value={op} control={<Radio/>} label={op}/>))}
                </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions className={"center"} style={{padding: "16px"}}>
            <Button disableElevation onClick={props.onClose} variant={"contained"}>
                Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant={"contained"}>
                Yes, Confirm
            </Button>
        </DialogActions>
    </Dialog>;
}

function ConfirmActionDialog(props) {
    const {open, onClose, onConfirm, title, content, confirmButton} = props;
    return <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={onClose}
    >
        <DialogContent style={{padding: "24px 32px"}}>
            <Typography variant={"subtitle2"} style={{fontSize: "16px"}} gutterBottom>{title}?</Typography>
            <Typography variant={"subtitle1"} style={{paddingTop: "12px"}}>
                {content}
            </Typography>
        </DialogContent>
        <DialogActions className={"center"} style={{padding: "16px"}}>
            <Button disableElevation onClick={onClose} variant={"contained"}>
                Cancel
            </Button>
            <Button onClick={onConfirm} color="primary" variant={"contained"}>
                {confirmButton ? confirmButton : "Proceed"}
            </Button>
        </DialogActions>
    </Dialog>;
}

export default ConfirmActionDialog;